interface MaintenanceDateFormatProps {
  dateTime: Date | null | undefined;
}

export function MaintenanceDateFormat({
  dateTime,
}: MaintenanceDateFormatProps) {
  if (dateTime === undefined) return <>-</>;
  if (dateTime === null)
    return <strong style={{ color: '#3cf05a' }}>Não está em manutenção</strong>;

  if (!(dateTime instanceof Date) || Number.isNaN(dateTime.getTime())) {
    return <strong>Data inválida</strong>;
  }

  const diff = Math.abs(new Date().getTime() - dateTime.getTime());
  const minutes = Math.ceil(diff / (1000 * 60));
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (minutes < 60)
    return <strong style={{ color: '#f09c3c' }}>{`${minutes} min`}</strong>;

  if (hours < 24)
    return <strong style={{ color: '#f09c3c' }}>{`${hours} h`}</strong>;

  return <strong style={{ color: '#f09c3c' }}>{`${days} dias`}</strong>;
}
