import { Switch, withRouter, Route as ReactRouterDom } from 'react-router-dom';
import { Route } from 'components/Route';
import { NotFound } from 'components/NotFound/index.js';
import { routes } from './routes';

export const RoutesContainer = withRouter(() => {
  return (
    <>
      <Switch>
        {routes.map(route => (
          <Route key={route.id} {...route} />
        ))}
        <ReactRouterDom component={NotFound} />
      </Switch>
    </>
  );
});
